.logo{
    width: 90px !important;
}
.documents{
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    margin-bottom: 10px;
}
.document{
    position: relative;
    text-align: center;
    margin-right: 20px;
}
.overflowVisible{
    overflow: visible !important;
}

.marginBottom24{
    margin-bottom: 24px;
}